import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Video from "../components/video"
import { IconContext } from "react-icons"
import { BsFillCameraVideoFill } from "react-icons/bs"
import { FaLightbulb } from "react-icons/fa";

import indexStyles from "../styles/pages/index.module.css"

import cameraIcon from "../images/camera-icon.png"
import aboutVideo from "../videos/logo-animation.mp4"
import aboutPosterImg from "../images/about-poster-img.png"
import panel1 from "../images/tayo.gif"
import panel2 from "../images/dead-kids.gif"
import panel3 from "../images/baril.gif"
import trainIcon from "../images/train-icon.png"
import train from "../images/train.png"

const IndexPage = () => (
  <Layout location="home">
    <SEO title="Home" />
    <div className={ indexStyles.aboutSection }>
      <div className={ indexStyles.aboutText }>
        <img src={ cameraIcon } alt=""/>
        <p>We can provide</p>
        <h3>Technical & Content Services For Film & Digital</h3>
        <Link to="/about/">Learn More</Link>
      </div>
      <div className={ indexStyles.aboutContent }>
        <Video src={ aboutVideo } poster={ aboutPosterImg } className={ indexStyles.aboutVideo } />
      </div>
    </div>
    <div className={ indexStyles.servicesSection }>
      <div className={ indexStyles.servicesContent }>
        <div className={ indexStyles.topRow }>
          <img src={ panel1 } alt=""/>
          <img src={ panel2 } alt=""/>
        </div>
        <div className={ indexStyles.bottomRow }>
          <img src={ panel3 } alt=""/>
        </div>
      </div>
      <div className={ indexStyles.servicesText }>
        <div className={ indexStyles.servicesTextWrapper }>
          <img src={ trainIcon } alt=""/>
          <h3>Why Creative Caboose?</h3>
          <p>We have the technical knowledge and market experience to handle various postprod requirements.</p>
          <Link to="/services/">Check Services</Link>
        </div>
      </div>
    </div>
    <div className={ indexStyles.contactSection }>
      <div className={ indexStyles.contactText }>
        <p>So what are you waiting for?</p>
        <h3>Come aboard and let's journey into a great adventure!</h3>
        <Link to="/contact/">Contact Us</Link>
      </div>
      <div className={ indexStyles.contactContent }>
        <img src={ train } alt=""/>
      </div>
    </div>
  </Layout>
)

export default IndexPage
